import React from 'react';
import useAnalyticsEventTracker from '../../ReactGA/useAnalyticsEventTracker';
import Section from '../../../HOC/Section';

import img1 from '../../../assets/moto/1.jpg';
import img2 from '../../../assets/moto/2.jpg';
import img3 from '../../../assets/moto/3.jpg';
import img4 from '../../../assets/moto/4.jpg';


const Moto = () => {
  const gaEventTracker = useAnalyticsEventTracker('Acessorios para motos');
  return (
    <Section id='moto'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Acessórios para </span>Motos
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          Os melhores acessórios para você e sua moto estão aqui.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img1} className='card-img-top' alt='Blog 1' />
                <div className='card-body'>
                  <h5 className='card-title'>Manete Retrátil para Honda CRF</h5>
                  <p className='card-text'>
                  O par de alavancas é feito de alumínio, extremamente resistente para suas trilhas. Por ser retrátil, as alavancas possuem restauração automática, garantindo a rápida recuperação em caso de uma queda.  <br />
                  <br /><br />
                  </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img2} className='card-img-top' alt='Blog 2' />
                <div className='card-body'>
                  <h5 className='card-title'>Manete Retrátil para Honda XR 250 Tornado – Nx 400 Falcon – Xr 200 e Xre 300</h5>
                  <p className='card-text'>
                  O par de alavancas é feito de alumínio, extremamente resistente para suas trilhas. Por ser retrátil, as alavancas possuem restauração automática, garantindo a rápida recuperação em caso de uma queda.</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img3} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Tampara para radiador para motos com marcador de temperatura </h5>
                  <p className='card-text'>
                  Esse marcador irá te ajudar a controlar a temperatura do motor da sua moto, evitando superaquecimento do motor.
Compatível com as marcas Honda, Yamaha, Kawasaki e Suzuki
<br /><br />
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img4} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Par de anel 44x3 - Viton</h5>
                  <p className='card-text'>
                  Kit com 2 anéis Viton, resistente a altas temperaturas.
Escape da: KTM, GASGAS, Beta, Sherco, Husqvarna.
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
           </div>
        </div>
      </div>
    </Section>
  );
};

export default Moto;
