import React from 'react';
import useAnalyticsEventTracker from '../../ReactGA/useAnalyticsEventTracker';
import Section from '../../../HOC/Section';

import img1 from '../../../assets/phone/1.jpg';
import img2 from '../../../assets/phone/2.jpg';
import img3 from '../../../assets/phone/3.jpg';
import img4 from '../../../assets/phone/4.jpg';
import img5 from '../../../assets/phone/5.jpg';
import img6 from '../../../assets/phone/6.jpg';
import img7 from '../../../assets/phone/7.jpg';
import img8 from '../../../assets/phone/8.jpg';
import img9 from '../../../assets/phone/9.jpg';
import img10 from '../../../assets/phone/10.jpg';
import img11 from '../../../assets/phone/11.jpg';
import img12 from '../../../assets/phone/12.jpg';
import img13 from '../../../assets/phone/13.jpg';
import img14 from '../../../assets/phone/14.jpg';
import img15 from '../../../assets/phone/15.jpg';
import img16 from '../../../assets/phone/16.jpg';

const Phone = () => {
  const gaEventTracker = useAnalyticsEventTracker('Smartsphones');
  return (
    <Section id='phone'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Smartphones </span>Resistentes
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          Celulares robustos são feitos para durar e resistir a impactos. Os smartphones robustos DOOGEE possuem certificações IP68, IP69K e MIL-STD-810G, que podem resistir a qualquer condição climática, impacto, chuva, poeira, areia, A erosão de substâncias como óleo e água também não são problema.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img1} className='card-img-top' alt='Blog 1' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S61 Pro 6Gb 128Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Android 12. IP69K.  Câmera 48MP + Visão Noturna
<br />
                  </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img2} className='card-img-top' alt='Blog 2' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S98 8Gb 256Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Android 12. IP69K.  Câmera 64MP + Visão Noturna
Display Traseiro.
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img3} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S88 Pro 6Gb 128Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Super Bateria de 10000 mAh. IP69K
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img4} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S35T 3GB 64Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Android 11. IP69K. Modelo entrada da Doogee, Super barato.
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img5} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S97 Pro 8Gb 128Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Super Bateria de 8500 mAh. Trena a Laser. Câmera 48MP
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img6} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S86 Pro 8Gb 128Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Super Bateria de 8500 mAh. Medido de temperatura. 
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img7} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S59 Pro 4Gb 128Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Super Bateria de 10050 mAh. Andorid 10. 
<br /><br />
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img8} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE V10 8Gb 128Gb 5G</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Super Bateria de 8500 mAh. Andorid 11. Possui 5G
Medidor de temperatura
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img9} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE V11 8Gb 128Gb 5G</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Super Bateria de 8500 mAh. Andorid 11. Possui 5G
Medidor de temperatura. Carregador Ultra rápido 65W
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img10} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE V20 8Gb 256Gb 5G</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Camera 64MP + Visão Noturna. Com Visor traseiro. Possui 5G
<br /><br /><br />
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img11} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S51 4GB 64Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Android 12. IP69K. Bateria 5180mAh
<br /><br /><br />
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img12} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S96GT 8Gb 256Gb</h5>
                  <p className='card-text'>
                  Lançamento Celular Parrudo, Resistente a Água; Poeira e Queda. IP69K Bateria de 6350 mAh. Câmera tripla AI de 48MP + Câmera de visão Noturna + 20MP. Super Camera Frontal 32MP. Android 12</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img13} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S88 Plus 8Gb 128Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. 
Super Bateria de 10000 mAh. IP69K
<br /><br />
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img14} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S89 PRO 8Gb 256Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. IP69K
Super Bateria de 12000 mAh. Câmera tripla AI de 64MP + Câmera de visão Noturna + 20MP 
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img15} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S96 PRO 8Gb 128Gb</h5>
                  <p className='card-text'>
                  Celular Parrudo, Resistente a Água; Poeira e Queda. IP69K
Bateria de 6350 mAh. Câmera tripla AI de 48MP + Câmera de visão Noturna + 20MP 
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img16} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smartphone Resistente DOOGEE S98 PRO 8Gb 256Gb</h5>
                  <p className='card-text'>
                  Lançamento Celular Parrudo, Resistente a Água; Poeira e Queda. IP69K
Equipado com uma Incrível Câmera Térmica + Visão Noturna. 
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('samrtephone')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Phone;
