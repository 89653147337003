import ReactGA from "react-ga";

const TRACKING_ID = "UA-245211695-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const useAnalyticsEventTracker = (category="site") => {
  const eventTracker = (action = "masterstecnologia", label = "masterstecnologia") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;