import React from 'react';
import Section from '../../../HOC/Section';

const mapMarker = () => {
  return (
    <Section id='map'>
      <div>
        <iframe
          title='mapMarker'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.2576069028114!2d-49.31446238499053!3d-25.194533683897397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x505605529344f882!2sMasters%20Tecnologia%20-%20Solu%C3%A7%C3%B5es%20em%20Conectividade%2C%20Celulares%20e%20Acess%C3%B3rios%20Eletr%C3%B4nicos!5e0!3m2!1spt-BR!2sbr!4v1666191474337!5m2!1spt-BR!2sbr'
          width='100%'
          height='450'
          frameBorder='0'
          style={{ border: 0, marginBottom: '-7px' }}
          allowFullScreen
        />
      </div>
    </Section>
  );
};

export default mapMarker;
