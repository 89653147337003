import React, { Fragment } from 'react';
// import ReactWhatsapp from "react-whatsapp";
// import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Header from '../UI/Header/Header';
import Footer from '../UI/Footer/Footer';
import Sections from '../sections/Sections';

const Layout = () => {
  return (
    <Fragment>
      <Header />
      <main>
      {/* <ReactWhatsapp number="+5541920042554" message="Bem-vindo, Masters Tecnologia" />
      <FloatingWhatsApp phoneNumber="+5541920042554" /> */}
        <Sections />
      </main>
      <Footer />
    </Fragment>
  );
};

export default Layout;
