import React from 'react';
import useAnalyticsEventTracker from '../../ReactGA/useAnalyticsEventTracker';
import Section from '../../../HOC/Section';

import img1 from '../../../assets/inter/1.jpg';
import img2 from '../../../assets/inter/2.jpg';
import img3 from '../../../assets/inter/3.jpg';
import img4 from '../../../assets/inter/4.jpg';
import img5 from '../../../assets/inter/5.jpg';
import img6 from '../../../assets/inter/6.jpg';
import img7 from '../../../assets/inter/7.jpg';
import img8 from '../../../assets/inter/8.jpg';
import img9 from '../../../assets/inter/9.jpg';
import img10 from '../../../assets/inter/10.jpg';
import img11 from '../../../assets/inter/11.jpg';
import img12 from '../../../assets/inter/12.jpg';
import img13 from '../../../assets/inter/13.jpg';

const Inter = () => {
  const gaEventTracker = useAnalyticsEventTracker('Intercomunicadores');
  return (
    <Section id='inter'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Intercomunicadores </span>
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          Os melhores Intercomunicadores para Capacete você encontra aqui. Nossos produtos trazem as principais tendências de tecnologia do mercado internacional, garantindo qualidade com preços que cabem no seu bolso.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img1} className='card-img-top' alt='Blog 1' />
                <div className='card-body'>
                  <h5 className='card-title'>Intercomunicador Para Capacete Ejeas Q7</h5>
                  <p className='card-text'>
                  Aceita Controle remoto de guidão
Incrível Bateria que pode durar Até 30 horas
Até 7 pessoas, Rádio FM, IP65
<br /><br /><br /><br />
                  </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img2} className='card-img-top' alt='Blog 2' />
                <div className='card-body'>
                  <h5 className='card-title'>Controle de Guidão Para Intercomunicador Q7 / V4 Plus</h5>
                  <p className='card-text'>
                  Conexão bluetooh, IP65. Compatível com os intercomunicadores EJEAS – Q7, Q20 e E300 V4Plus
Resistente a Água
<br /><br /><br /><br />
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img3} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Intercomunicador e Câmera para Capacete – Maxto M3 </h5>
                  <p className='card-text'>
                  Alcance de 1000m, até 6 pessoas na mesma frequência, bateria de até 32 horas e resistente a água. Câmera Sony HD Wide Angle Leans 1080P, Gravação em HD, Ângulo de gravação 120º. Gravação e Loop (Som e vídeo)
                           </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img4} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Intercomunicador para Capacete – Ejeas V6 Pro </h5>
                  <p className='card-text'>
                  Alcance de 1200m, até 6 pessoas, bateria de até 10 horas e a Resistente a água.
                  <br /><br /><br /><br />
                  </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img5} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Intercomunicador para Capacete + Controle para Guidão – Netphone V8 </h5>
                  <p className='card-text'>
                  Alcance de 1200m, até 5 pessoas na mesma frequência, bateria de até 150 horas, resistente a água, possui também Rádio FM.</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img6} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Intercomunicador para capacete - Ejeas E6</h5>
                  <p className='card-text'>
                  Alcance de 1200m, pareamento para até 6 pessoas, bateria de até 10 horas e a Resistente a água.
                  <br /><br /><br />
                   </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img7} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Suporte Adesivo para Intercomunicador de Capacete – Ejeas V4 V6</h5>
                  <p className='card-text'>
                  Para quem procura repor ou para capacete reserva.
Modelo V4/V6 e similares. </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img8} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Suporte para intercomunicador - Ejeas V6</h5>
                  <p className='card-text'>
                  Suporte Adesivo para Intercomunicador Ejeas V6 V4; Vnetphone V6 V4, Lx R6
                  <br /><br />
                  </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img9} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Suporte para intercomunicador - Ejeas E6</h5>
                  <p className='card-text'>
                  Suporte para colar e fixar no capacete com fita 3M
                  <br /><br />
                  </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img10} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Kit microfone de capacete para Intercomunicadores – E6, V6, V4, Q7 e similares </h5>
                  <p className='card-text'>
                  Kit acompanha fone e microfone de haste + microfone para capacete fechado.
                  <br /><br /><br /><br /><br />
                  </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img11} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Fones de ouvido internos para capacete - MX</h5>
                  <p className='card-text'>
                  De fácil instalação os autofalantes se ajustam perfeitamente no capacete, proporcionando conforto, reduzindo ruídos. Com a função de controle de três botões de operação, permite ajustar o volume; reproduzir ou pausar músicas e receber chamadas.</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img12} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Suporte de Fixação para intercomunicador – Ejeas Q7</h5>
                  <p className='card-text'>
                  Para quem procura repor ou para capacete reserva.
                  <br /><br /><br /><br /><br /><br />
                  </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img13} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Kit Clip de Metal para Suporte de Intercomunicador – V6 e V4 </h5>
                  <p className='card-text'>
                  Clip de metal para reposição dos modelos V4, V6 e similares.</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('intercomunicador')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Inter;
