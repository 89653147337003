import React from 'react';
import Section from '../../../HOC/Section';

import img01 from "../../../assets/1.jpeg";
import img02 from "../../../assets/2.jpeg";
import img03 from "../../../assets/3.jpeg";
import img04 from "../../../assets/4.jpeg";
import img05 from "../../../assets/5.jpeg";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
};

const items = [
  <img src={img01} onDragStart={handleDragStart} role="presentation" alt="" width="100%"/>,
  <img src={img02} onDragStart={handleDragStart} role="presentation" alt="" width="100%"/>,
  <img src={img03}  onDragStart={handleDragStart} role="presentation" alt="" width="100%"/>,
  <img src={img04}  onDragStart={handleDragStart} role="presentation" alt="" width="100%"/>,
  <img src={img05}  onDragStart={handleDragStart} role="presentation" alt="" width="100%"/>,
];

const home = () => {
  return (
    <Section id='home'>
      <AliceCarousel
      mouseTracking items={items}
      animationDuration='400'
      animationType='slide'
      autoPlay='1'
      infinite='1'
      autoPlayInterval='3000'
      autoHeight="true"
      autoWidth= "true"
      responsive={responsive}
      controlsStrategy="alternate"
       />
    </Section>
  );
  }
export default home;
