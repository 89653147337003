import React from 'react';

import Section from '../../../HOC/Section';
import bgImage from '../../../assets/img/facts_bg.jpg';

const Facts = () => {
  return (
    <Section id='facts'>
      <div
        className='facts-container'
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className='container pt-5 pb-4'>
          <div className='row'>
            <div className='col-sm-3 col-md-4 text-center mb-3'>
              <div className='mb-2 facts-icon'>
                <i className='fas fa-users' />
              </div>
              <h4 className='facts-counter text-light'>Visão</h4>
              <h6 className='facts-title text-light'>Tornar empresa referência no mercado em que atuamos, reconhecida pelo atendimento e pelos produtos e serviços oferecidos.</h6>
            </div>
            <div className='col-sm-3 col-md-4 text-center mb-3'>
              <div className='mb-2 facts-icon'>
                <i className='fas fa-grin-beam' />
              </div>
              <h4 className='facts-counter text-light'>Missão</h4>
              <h6 className='facts-title text-light'>Atender à crescente demanda por atendimento rápido e eficaz, agregando valor ao mercado que atuamos, com redução de custo de produtos e serviços, buscando a constante excelência.</h6>
            </div>
            <div className='col-sm-3 col-md-4 text-center mb-3'>
              <div className='mb-2 facts-icon'>
                <i className='fas fa-project-diagram' />
              </div>
              <h4 className='facts-counter text-light'>Valores</h4>
              <h6 className='facts-title text-light'>Preservar em primeiro lugar o atendimento ao nossos clientes.
Comprometimento com a excelência no pós venda e suporte técnico.</h6>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Facts;
